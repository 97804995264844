import React from 'react';
import {
    AmountInput,
    Checkbox,
    FlexColumns,
    Input,
    SuperListHeader,
    Textarea,
    Typography,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateLocationDto } from '@escapenavigator/types/dist/location/create-location.dto';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { fetchCashboxes, removeLocation, upsertLocation } from 'src/store';
import { useAppDispatch } from 'src/store/hooks';
import { getLocales } from 'src/utils/get-locales';

type Props = {
    location?: LocationRO;
    initial?: boolean;
    close: () => void;
    t: TFunction;
};

export const LocationModal: React.FC<Props> = ({
    location, close, initial, t,
}) => {
    const dispatch = useAppDispatch();
    const { locations } = useApi();
    const {
        profile: { language, availableLanguages },
    } = useCurrentUser();

    const recordId = location?.id;

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        removeRequest: locations.remove,
        saveRequest: recordId ? locations.update : locations.create,
        saveCallback: (savedRecord) => {
            dispatch(upsertLocation(savedRecord));
            dispatch(fetchCashboxes());
        },
        removeCallback: (removedRecord) => dispatch(removeLocation(removedRecord.id)),
        close,
    });

    const title = location?.title || t('modalsHeader.locations');

    const locales = getLocales({
        language,
        availableLanguages,
        locales: location?.locales || [],
        defaultLocale: {
            howToFind: location?.howToFind,
            prepareText: location?.prepareText,
        },
    });

    return (
        <RestForm
            recordId={ recordId }
            title={ title }
            initialValues={ serializeRecord(
                CreateLocationDto,
                { ...location, locales } || { locales },
            ) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ !initial && remove }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                        { recordId && (
                            <Input
                                dataTestId="title"
                                label={ t('tables.title') }
                                value={ values.title }
                                onChange={ handleChange('title') }
                                error={ touched.title && errors.title }
                                block={ true }
                            />
                        ) }

                        <Input
                            dataTestId="address"
                            label={ t('component.fullAddress') }
                            value={ values.address }
                            onChange={ handleChange('address') }
                            error={ touched.address && errors.address }
                            block={ true }
                        />
                        <Input
                            dataTestId="phone"
                            label={ t('phone') }
                            value={ values.phone }
                            onChange={ handleChange('phone') }
                            error={ touched.phone && errors.phone }
                            block={ true }
                        />

                        { !initial && (
                            <Input
                                dataTestId="ip"
                                label="IP"
                                hint={ t('ipDescription') }
                                value={ values.ip }
                                onChange={ handleChange('ip') }
                                block={ true }
                            />
                        ) }

                        <FlexColumns columns={ 1 } gr={ 0 } gc={ 0 }>
                            <SuperListHeader text={ t('typography.advantagesLocation') } />

                            <Typography.Text view="primary-small" color="secondary" weight="medium">
                                { t('typography.adviceLocation') }
                            </Typography.Text>
                        </FlexColumns>

                        <Checkbox
                            dataTestId="wifi"
                            label={ t('component.availabilityWifi') }
                            checked={ values.wifi }
                            onChange={ (e, { checked }) => setFieldValue('wifi', checked) }
                        />
                        <Checkbox
                            dataTestId="wardrobe"
                            label={ t('component.availabilityWardrobe') }
                            checked={ values.wardrobe }
                            onChange={ (e, { checked }) => setFieldValue('wardrobe', checked) }
                        />

                        <Checkbox
                            dataTestId="freeParking"
                            label={ t('component.freeParking') }
                            checked={ values.freeParking }
                            onChange={ (e, { checked }) => setFieldValue('freeParking', checked) }
                        />
                        <Checkbox
                            dataTestId="parking"
                            label={ t('component.parking') }
                            checked={ values.parking }
                            onChange={ (e, { checked }) => setFieldValue('parking', checked) }
                        />
                        <Checkbox
                            dataTestId="waitingArea"
                            label={ t('component.waitingArea') }
                            checked={ values.waitingArea }
                            onChange={ (e, { checked }) => setFieldValue('waitingArea', checked) }
                        />

                        { values.waitingArea && (
                            <AmountInput
                                dataTestId="waitingAreaSize"
                                label={ t('component.waitingAreaSize') }
                                suffix={ t('ppl') }
                                min="0"
                                required={ values.waitingArea }
                                error={ touched.waitingAreaSize && errors.waitingAreaSize }
                                value={ values.waitingAreaSize }
                                onChange={ (e, { value }) => setFieldValue('waitingAreaSize', value) }
                                block={ true }
                            />
                        ) }

                        <Textarea
                            dataTestId="howToFind"
                            label={ t('component.howToFind') }
                            value={ values.howToFind }
                            onChange={ handleChange('howToFind') }
                            block={ true }
                            maxLength={ 600 }
                            counter={ true }
                        />

                        <Textarea
                            dataTestId="prepareText"
                            label={ t('Что важно знать перед бронированием') }
                            value={ values.prepareText }
                            onChange={ handleChange('prepareText') }
                            error={ touched.prepareText && errors.prepareText }
                            block={ true }
                            maxLength={ 1000 }
                            counter={ true }
                        />

                        <Input
                            dataTestId="site"
                            label="Website"
                            value={ values.site }
                            onChange={ handleChange('site') }
                            error={ touched.site && errors.site }
                            block={ true }
                        />
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrashS } from '@alphakits/icons';
import {
    formatDate, IconButton, Message, showError,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { UserMessageResponseObject } from '@escapenavigator/types/dist/user-message/user-message.ro';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectUserById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    comment: UserMessageResponseObject;
    onRemove: (id: UserMessageResponseObject['id']) => void;
};

export const Comment: React.FC<Props> = ({ comment, onRemove }) => {
    const { userMessages } = useApi();
    const { i18n } = useTranslation();

    const {
        current: { user },
    } = useCurrentUser();
    const { photo, name, surname } = useAppSelector(selectUserById(comment.userId));

    const { removeFetch, removeLoading } = useApiMethod({
        api: userMessages.remove,
        successCallback: () => onRemove(comment.id),
        errorCallback: (err) => showError(err?.message),
    });

    const addon =
        onRemove && (user.id === 999999999 || comment.userId === user.id) ? (
            <IconButton
                onClick={ () => removeFetch(comment.id) }
                icon={ TrashS }
                className="noPrint"
                loading={ removeLoading }
                view="ghost"
                size="s"
            />
        ) : null;

    return (
        <Message
            key={ comment.id }
            title={ `${name} ${surname}` }
            withImage={ true }
            image={ photo || undefined }
            photos={ comment.photos }
            text={ comment.text }
            date={ formatDate(comment.createdAt, { lang: i18n.language }) }
            rightAddons={ addon }
        />
    );
};

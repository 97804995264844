/* eslint-disable complexity */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box, formatDate, InfoBlockHeader, InfoBlockItem, ModalContext,
} from '@alphakits/ui/dist';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import { getFullName } from '@escapenavigator/utils/dist/get-full-name';
import { ClientModal } from 'src/modals/client';

type Props = {
    client?: ClientRO;
    cb?: (client: ClientRO) => void;
    noOnClick?: boolean;
    short?: boolean;
};

export const ClientInfo: React.FC<Props> = ({
    client, noOnClick, cb, short,
}) => {
    const { t, i18n } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const openClientModal = openModal(ClientModal, 'm', true);

    if (!client.id) return null;

    return (
        <Box background="var(--color-bg-primary)" border={ true } rounded="sm" padding="md">
            <InfoBlockHeader
                title={ `${t('component.player')} №${client.id}` }
                onClick={ noOnClick ? undefined : () => openClientModal({ recordId: client.id, cb }) }
            />

            <InfoBlockItem label={ t('tables.client') } value={ getFullName(client) } />

            { client.phone && <InfoBlockItem label={ t('phone') } value={ client.phone } /> }

            <InfoBlockItem label={ t('email') } withCopyButton={ true } value={ client?.email } />

            { /* <InfoBlockItem label={ t('modalsHeader.trustInfo') } value={ `${client?.trustIndex}` } /> */ }

            <InfoBlockItem label={ t('Кол-во броней') } value={ `${client?.ordersCount}` } />

            <InfoBlockItem
                label={ t('Кол-во сертификатов') }
                value={ `${client?.certificatesCount}` }
            />

            { !short && client?.lastOrderDate && (
                <InfoBlockItem
                    label={ t('tables.lastDateBooking') }
                    value={ formatDate(client?.lastOrderDate, {
                        lang: i18n.language,
                        format: 'dd.MM.yyyy',
                    }) }
                />
            ) }

            { client?.blockedDate && (
                <InfoBlockItem
                    label={ t('clientBlocked') }
                    color="negative"
                    value={ formatDate(client?.blockedDate, {
                        lang: i18n.language,
                        format: 'dd.MM.yyyy',
                    }) }
                />
            ) }
            { !short && (
                <InfoBlockItem
                    label={ t('address') }
                    withCopyButton={ true }
                    value={
                        client.address
                            ? `${client.address}, ${client.city}, ${client.postcode}`
                            : '-'
                    }
                />
            ) }
        </Box>
    );
};

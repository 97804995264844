import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from '@alphakits/ui/dist';
import { QuestroomCancelationTypeEnum } from '@escapenavigator/types/dist/questroom/enum/questroom-cancelation-type.enum';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { getPrepaymentTitle } from 'src/pages/settings/booking-settings/booking-rules/rule-columns';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getHoursLabel } from 'src/utils/get-hours-label';

type Props = {
    rule: {
        title: string;
        minHoursForBooking: number;
        prepayment: number;
        prepaymentType: PrepaymentTypeEnum;
        cancelationRule: QuestroomCancelationTypeEnum;
        minHoursForFreeCanceling: number;
    };
};

export const getCancelationLabel = (rule: Props['rule'], t) => {
    let cancelationLabel = t(`options.cancelationRiles.${rule.cancelationRule}`);

    if (rule.cancelationRule !== QuestroomCancelationTypeEnum.NO) {
        cancelationLabel = t('cancelationLabel', {
            rule: t(`options.cancelationRiles.${rule.cancelationRule}`),
            time: ` ${rule.minHoursForFreeCanceling} ${getHoursLabel(
                rule.minHoursForFreeCanceling,
                t,
            )}`,
        });
    }

    return cancelationLabel;
};

export const BookingRuleDescription: React.FC<Props> = ({ rule }) => {
    const { t } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    if (!rule) {
        return <Cell.Base title="The booking rule for this slot has been removed." />;
    }

    return (
        <React.Fragment>
            <Cell.Base
                title={ t('component.minHoursForBooking') }
                subtitle={ `${rule.minHoursForBooking} ${t('часов до начала игры')}` }
            />

            <Cell.Base
                title={ t('Предоплата онлайн') }
                subtitle={ getPrepaymentTitle(rule.prepaymentType, rule.prepayment, currency, t) }
            />

            <Cell.Base
                title={ t('Правило отмены квеста не в сррок') }
                subtitle={ getCancelationLabel(rule, t) }
            />
        </React.Fragment>
    );
};

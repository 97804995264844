import React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoMarkS } from '@alphakits/icons';
import {
    Amount,
    CopyButton,
    FlexColumns,
    formatDate,
    IconButton,
    Padding,
    SuperListItem,
    Tooltip,
    Typography,
} from '@alphakits/ui/dist';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { TransactionSourceEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-source.enum';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectCashboxById } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { getTransactionStatusText } from 'src/utils/get-transaction-status';

export type RefundProps = {
    cashboxId: number;
    refundAmount: number;
    comment: string;
};

type Props = {
    transaction: OrderRO['transactions'][0] | CertificateSaleRO['transactions'][0];
};

export const Transaction: React.FC<Props> = ({ transaction }) => {
    const cashbox = useAppSelector(selectCashboxById(transaction.cashboxId));
    const { t, i18n } = useTranslation();

    const {
        profile: { currency },
    } = useCurrentUser();

    const text = transaction.amount < 0 ? t('Возврат') : t('Платеж');

    const status = getTransactionStatusText(transaction.type, t);
    const increase =
        transaction.paymentId && transaction.provider === TransactionSourceEnum.INSIDE
            ? 0
            : transaction.includedIncrease;

    return (
        <div style={ { opacity: status ? 0.5 : 1 } }>
            { !!increase && (
                <Padding padding="0 0 12px 0">
                    <SuperListItem
                        text={ `${t('Наценка при онлайн-оплате')}` }
                        disabled={ true }
                        color="primary"
                        rightAddons={ (
                            <Amount
                                value={ increase }
                                currency={ currency }
                                color="primary"
                                type="decimal"
                                weight="bold"
                                view="title"
                            />
                        ) }
                    />
                </Padding>
            ) }
            <SuperListItem
                text={ `${status} ${text}, ${cashbox.title}` }
                disabled={ !transaction.paymentId }
                color={ transaction.amount < 0 ? 'negative' : 'primary' }
                hint={ formatDate(transaction.createdAt, { lang: i18n.language }) }
                leftAddons={ (
                    <Tooltip
                        trigger="click"
                        position="top"
                        content={ (
                            <FlexColumns columns={ 1 } gr={ 8 }>
                                { transaction.comment && (
                                    <Typography.Text view="title" weight="regular">
                                        { transaction.comment }
                                    </Typography.Text>
                                ) }

                                <CopyButton
                                    fullText={ transaction.paymentId }
                                    text={ `${
                                        transaction.provider === TransactionSourceEnum.PAYPAL
                                            ? 'PayPal'
                                            : 'Stripe'
                                    } payment Id: ${transaction.paymentId}` }
                                />
                            </FlexColumns>
                        ) }
                        dataTestId="test-id"
                    >
                        <IconButton view="ghost" icon={ InfoMarkS } />
                    </Tooltip>
                ) }
                rightAddons={ (
                    <Amount
                        value={ transaction.amount * -1 }
                        currency={ currency }
                        type="decimal"
                        color={ transaction.amount < 0 ? 'negative' : 'positive' }
                        weight="bold"
                        view="title"
                    />
                ) }
            />
        </div>
    );
};

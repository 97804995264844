import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ExcelM, SearchM } from '@alphakits/icons';
import {
    Button,
    CalendarInput,
    Flex,
    FlexColumns,
    Loader,
    Select,
    Typography,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { CertificatesaleStatusEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-status.enum';
import { QueryCertificatesaleDto } from '@escapenavigator/types/dist/certificate-sale/query-certificatesale.dto';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { FormikProps, FormikValues } from 'formik';
import { useApi } from 'src/providers/api/context';
import { downloadBlob } from 'src/utils/download-blob';

type Props = {
    apply: (values: FormikValues) => void;
    initialValues: Partial<QueryCertificatesaleDto>;
    close: () => void;
};

const Form = ({
    props: { values, setFieldValue },
    close,
}: {
    props: FormikProps<QueryCertificatesaleDto>;
    close: () => void;
}) => {
    const { t, i18n } = useTranslation();
    const { certificatesales } = useApi();

    const { exportCertificatesFetch, exportCertificatesLoading } = useApiMethod({
        api: certificatesales.exportCertificates,
        successCallback: ({ data, headers }) => {
            downloadBlob(data, headers);
            close();
        },
    });

    const { countData, countLoading, countFetch } = useApiMethod({
        api: certificatesales.count,
    });

    useEffect(() => {
        countFetch(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return (
        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                <Typography.Text view="title" weight="medium">
                    { t('Дата создания') }
                </Typography.Text>
                <FlexColumns columns={ 2 } gr={ 16 } gc={ 16 }>
                    <CalendarInput
                        dataTestId="created.0"
                        label={ t('from') }
                        value={ values.created[0] }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('created.0', '');
                        } }
                        onChange={ (e, { value }) => {
                            setFieldValue('created.0', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                    <CalendarInput
                        dataTestId="created.1"
                        label={ t('to') }
                        clear={ true }
                        onClear={ () => {
                            setFieldValue('created.0', '');
                        } }
                        value={ values.created[1] }
                        onChange={ (e, { value }) => {
                            setFieldValue('created.1', value);
                        } }
                        block={ true }
                        lang={ i18n.language }
                    />
                </FlexColumns>
            </FlexColumns>

            <Select
                dataTestId="questroomId"
                allowUnselect={ true }
                block={ true }
                optionsListWidth="field"
                label={ t('component.status') }
                options={ enumToOptions(CertificatesaleStatusEnum, t, 'certificateStatus') }
                selected={ values.certificateStatus }
                onChange={ ({ selected }) => setFieldValue('certificateStatus', selected?.key) }
            />

            <Select
                dataTestId="source"
                block={ true }
                allowUnselect={ true }
                optionsListWidth="field"
                label={ t('tables.source') }
                options={ enumToOptions(SourceEnum, t, 'source') }
                selected={ values.source }
                onChange={ ({ selected }) => setFieldValue('source', selected?.key) }
            />

            <Select
                dataTestId="delivery"
                block={ true }
                allowUnselect={ true }
                optionsListWidth="field"
                label={ t('component.deliveryType') }
                options={ enumToOptions(CertificatesaleDeliveryTypeEnum, t, 'delivery') }
                selected={ values.delivery }
                onChange={ ({ selected }) => setFieldValue('delivery', selected?.key) }
            />

            <Flex>
                <Flex gap="sm" justify="start">
                    <SearchM />

                    <Typography.Text view="component" color="secondary">
                        { t('Найдено записей') }
                    </Typography.Text>

                    { countLoading ? (
                        <Loader />
                    ) : (
                        <Typography.Text view="component" weight="bold">
                            { countData }
                        </Typography.Text>
                    ) }
                </Flex>

                <Button
                    view="link"
                    disabled={ countLoading || !countData }
                    size="xs"
                    leftAddons={ <ExcelM /> }
                    onClick={ () => exportCertificatesFetch(values) }
                    loading={ exportCertificatesLoading }
                >
                    { t('Export to excel') }
                </Button>
            </Flex>
        </FlexColumns>
    );
};

export const CertificatesFiltersModal: React.FC<Props> = ({ initialValues, close, apply }) => {
    const { t } = useTranslation();

    return (
        <RestForm
            save={ (values) => {
                apply(values);
                close();
            } }
            title={ t('filters.label') }
            submitButtonText={ t('Применить') }
            initialValues={ serializeRecord(QueryCertificatesaleDto, initialValues || {}) }
            close={ close }
            t={ t }
        >
            { (props: FormikProps<QueryCertificatesaleDto>) => <Form props={ props } close={ close } /> }
        </RestForm>
    );
};

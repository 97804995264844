import React from 'react';
import { GearM, PersonsTwoM, TicketOutlineM } from '@alphakits/icons';
import {
    Amount, BgColors, CopyButton, formatDate, Image, Status,
} from '@alphakits/ui';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { SourceEnum } from '@escapenavigator/types/dist/shared/source.enum';
import { TFunction } from 'i18next';
import { AlphaLogo } from 'src/components/layout/logo/alpha-logo';
import { getCertificateData } from 'src/utils/get-certificate-data';

const icons = {
    www: <TicketOutlineM />,
    widget: <TicketOutlineM />,
    user: <PersonsTwoM />,
    support: <GearM />,
    agregator: <AlphaLogo />,
};
const colors: Record<string, BgColors> = {
    [SourceEnum.WIDGET]: 'accent',
    [SourceEnum.USER]: 'attention',
    [SourceEnum.AGREGATOR]: 'positive',
};

type Props = {
    t: TFunction;
    currency: ProfileCurrencyEnum;
    i18n: any;
};

export const CertificatesColumns = ({
    t,
    currency,
    i18n,
}: Props): TableColumns<CertificateSaleRO> => [
    {
        header: t('tables.dateOfSale'),
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) =>
                formatDate(row.createdAt, { format: 'dd MMMM, HH:mm', lang: i18n.language }),
            icon: ({ row }) => (
                <Image view="ellipse" bgColor={ colors[row.source] } icon={ icons[row.source] } />
            ),
        },
    },

    {
        header: t('tables.certificate'),
        accessor: 'certificate',
        row: {
            title: ({ row }) => <CopyButton text={ row.code } />,
            subtitle: ({ row }) => row.certificate?.title || '-',
        },
    },

    {
        header: t('tables.client'),
        accessor: 'certificate',
        row: {
            title: ({ row }) => `${row.client?.name} ${row.client?.surname}`,
            subtitle: ({ row }) => row.client?.phone,
        },
    },

    {
        header: t('tables.delivery'),
        accessor: 'deliveryType',
        row: {
            title: ({ row }) =>
                (row.deliveryType ? t(`options.delivery.${row.deliveryType}`) : '-'),
        },
    },
    {
        header: t('tables.price'),
        accessor: 'total',
        row: {
            title: ({ row }) => (
                <Amount color="primary" type="decimal" currency={ currency } value={ row.total } />
            ),
        },
    },

    {
        header: t('Годен до'),
        accessor: 'expireDate',
        sort: true,
        row: {
            title: ({ row }) =>
                formatDate(row.expireDate, { format: 'dd MMMM yyyy', lang: i18n.language }),
        },
    },
    {
        header: '',
        align: 'right',
        accessor: 'sended',
        row: {
            title: ({ row }) => {
                const data = getCertificateData(row, t);

                return (
                    <Status color={ data.color } view="contrast">
                        { data.title }
                    </Status>
                );
            },
        },
    },
];

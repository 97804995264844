import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput, FlexColumns, ListAllert, Loader,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { UpdateOrderSlotDto } from '@escapenavigator/types/dist/order/update-order-slot.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { differenceInDays, format } from 'date-fns';
import { ModalSection } from 'src/components/layout/modal-section';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { WidgetSlot } from 'src/modals/components/widget-slot';
import { useApi } from 'src/providers/api/context';
import { selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    close: () => void;
    setOrder: (order: OrderRO) => void;
    order: OrderRO;
};

export const ChangeSlot: React.FC<Props> = ({ close, order, setOrder }) => {
    const { orders, slots } = useApi();
    const { t, i18n } = useTranslation();

    const questrooms = useAppSelector(selectAllQuestrooms({})) || [];

    const [localQuestroomId, setQuestroomId] = useState(order.questroomId);
    const [localDate, setDate] = useState(format(new Date(order.utcDate), 'yyyy-MM-dd'));

    const {
        oneQuestroomDaySlotsData,
        oneQuestroomDaySlotsFetch,
        oneQuestroomDaySlotsLoading,
        oneQuestroomDaySlotsError,
    } = useApiMethod({
        api: slots.oneQuestroomDaySlots,
    });

    useEffect(() => {
        oneQuestroomDaySlotsFetch({
            date: localDate,
            questroomId: `${localQuestroomId}`,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localDate, localQuestroomId]);

    const { updateSlotError, updateSlotLoading, updateSlotFetch } = useApiMethod({
        api: orders.updateSlot,
        successCallback: ({ data }) => {
            setOrder(data);
            close();
        },
    });

    const save = async ({ slotId: id }) => {
        if (id === order.slotId) {
            return close();
        }

        const isPastDate = differenceInDays(new Date(localDate), new Date()) < 0;

        if (isPastDate) {
            // eslint-disable-next-line no-alert, no-restricted-globals
            const isConfirmed = confirm(t('slot_in_past'));

            if (!isConfirmed) return null;
        }

        return updateSlotFetch({
            id: order.id,
            data: { slotId: id },
        }).then(() => close());
    };

    const error = updateSlotError || oneQuestroomDaySlotsError;

    return (
        <RestForm
            initialValues={ serializeRecord(UpdateOrderSlotDto, {
                slotId: order.slotId,
            }) }
            validate={ validateByDto(t) }
            updating={ updateSlotLoading }
            removing={ false }
            title={ t('Изменить квест и время') }
            t={ t }
            close={ close }
            save={ save }
        >
            { ({ values, setFieldValue }) => (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <QuestroomsSelect
                                selected={ localQuestroomId }
                                questrooms={ questrooms }
                                onChange={ ({ selected }) => setQuestroomId(+selected?.key) }
                                label={ t('Выберите квест') }
                            />

                            <CalendarInput
                                size="m"
                                lang={ i18n.language }
                                onChange={ (_, { value }) => setDate(value) }
                                block={ true }
                                label={ t('Выберите дату') }
                                value={ localDate }
                            />
                        </FlexColumns>

                        { oneQuestroomDaySlotsLoading && !oneQuestroomDaySlotsError ? (
                            <Loader />
                        ) : (
                            <ModalSection title={ t('Расписание') }>
                                { oneQuestroomDaySlotsData && oneQuestroomDaySlotsData.length ? (
                                    <FlexColumns columns={ 5 } gc={ 12 } gr={ 12 }>
                                        { oneQuestroomDaySlotsData
                                            .sort((a, b) => (a.start > b.start ? 1 : -1))
                                            .map((i) => {
                                                const [hh, mm] = i.start.split(':');

                                                return (
                                                    <WidgetSlot
                                                        selected={ i.id === values.slotId }
                                                        onClick={ () => {
                                                            setFieldValue('slotId', i.id);
                                                        } }
                                                        disabled={
                                                            i.status === 'bussy' ||
                                                            i.status === 'no-resources' ||
                                                            i.status === 'hold'
                                                        }
                                                        time={ `${hh}:${mm}` }
                                                        key={ i.id }
                                                    />
                                                );
                                            }) }
                                    </FlexColumns>
                                ) : (
                                    <div>No slots</div>
                                ) }
                            </ModalSection>
                        ) }

                        { error?.message && <ListAllert view="negative" text={ error.message } /> }
                    </FlexColumns>
                </React.Fragment>
            ) }
        </RestForm>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { PlusS } from '@alphakits/icons';
import {
    Checkbox, Collapse, Flex, FlexColumns, Input, Tag, Typography,
} from '@alphakits/ui/dist';
import { RuleRO } from '@escapenavigator/types/dist/slot-rule/rule.ro';
import { SlotAutocompliteFormDto } from '@escapenavigator/types/dist/slot/slot-autocomplite-form.dto';
import { FormikProps } from 'formik';

import { BookingRuleForm } from '../booking-rule-form';
import { BookingRuleDescription } from '../booking-rules-description';

type Rule = Pick<
    SlotAutocompliteFormDto,
    | 'minHoursForBooking'
    | 'prepayment'
    | 'minHoursForFreeCanceling'
    | 'prepaymentType'
    | 'title'
    | 'saved'
    | 'cancelationRule'
    | 'ruleId'
>;

type Props = {
    rules: RuleRO[];
    setFieldValue: FormikProps<Partial<Rule>>['setFieldValue'];
    values: FormikProps<Partial<Rule>>['values'];
    errors: FormikProps<Rule>['errors'];
    touched: FormikProps<Rule>['touched'];
};

export const BookingRule: React.FC<Props> = ({
    setFieldValue, values, errors, touched, rules,
}) => {
    const { t } = useTranslation();

    const rule = values.ruleId && rules.find((r) => r.id === values.ruleId);

    return (
        <FlexColumns columns={ 1 } gc={ 20 }>
            <Typography.Title tag="div" defaultMargins={ true } view="xxsmall" weight="bold">
                { t('bookingRules.title') }
            </Typography.Title>

            { !!rules.length && (
                <Flex justify="start" gap="sm" wrap={ true }>
                    { rules
                        .filter((r) => r.saved)
                        .map((r) => (
                            <Tag
                                text={ r.title }
                                size="m"
                                onClick={ () => setFieldValue('ruleId', r.id) }
                                view={ values.ruleId === r.id ? 'primary-inverted' : 'primary' }
                            />
                        )) }
                    <Tag
                        text={ t('Задать условия') }
                        size="m"
                        onClick={ () => {
                            setFieldValue('ruleId', null);
                            setFieldValue('title', null);
                        } }
                        view={ values.ruleId ? 'primary' : 'primary-inverted' }
                        leftAddons={ <PlusS /> }
                    />
                </Flex>
            ) }

            { !values.ruleId && (
                <BookingRuleForm
                    values={ values }
                    setFieldValue={ setFieldValue }
                    errors={ errors }
                    touched={ touched }
                />
            ) }

            { !values.ruleId && !!rules.length && (
                <React.Fragment>
                    <Checkbox
                        label={ t('Сохарить правила бронирования на будущее?') }
                        checked={ values.saved }
                        onChange={ (e, { checked }) => setFieldValue('saved', checked) }
                    />

                    { values.saved && (
                        <Input
                            dataTestId="title"
                            label={ t('tables.title') }
                            value={ values.title }
                            error={ touched.title && errors.title }
                            onChange={ (e) => setFieldValue('title', e.target.value) }
                            required={ true }
                            block={ true }
                        />
                    ) }
                </React.Fragment>
            ) }

            { !values.ruleId && !rules.length && (
                <Typography.Text view="primary-medium">{ t('bookingRules.hint') }</Typography.Text>
            ) }

            { rule && (
                <Collapse
                    collapsedLabel={ t('showBookingRules') }
                    expandedLabel={ t('Скрыть') }
                    dataTestId="minHoursForBookingCollapse"
                >
                    <FlexColumns columns={ 1 } gr={ 8 }>
                        <BookingRuleDescription rule={ rule } />
                    </FlexColumns>
                </Collapse>
            ) }
        </FlexColumns>
    );
};

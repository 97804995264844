/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, InfoBlockHeader, InfoBlockItem } from '@alphakits/ui/dist';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';

type Props = {
    certificatesale: CertificateSaleRO;
    onClick?: () => void;
};

export const CertificatesaleDescription: React.FC<Props> = ({ certificatesale, onClick }) => {
    const { t, i18n } = useTranslation();

    return (
        <React.Fragment>
            <InfoBlockHeader
                onClick={ onClick }
                title={ `${t('tables.certificate')} №${certificatesale.code}` }
            />

            <InfoBlockItem
                label={ t('Действует до') }
                value={ formatDate(certificatesale.expireDate, {
                    lang: i18n.language,
                    format: 'dd MMMM yyyy',
                }) }
            />

            <InfoBlockItem
                label={ t('tables.source') }
                value={ t(`options.source.${certificatesale.source}`) }
            />
            <InfoBlockItem
                label={ t('Дата создания') }
                value={ formatDate(certificatesale.createdAt, { lang: i18n.language }) }
            />
        </React.Fragment>
    );
};

import React from 'react';
import { InfoMarkS, OpenS } from '@alphakits/icons';
import {
    Amount, CopyButton, formatDate, IconButton, Tooltip,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { TransactionSourceEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-source.enum';
import { TransactionRO } from '@escapenavigator/types/dist/transaction/transaction.ro';
import { UserCell } from 'src/components/user-cell';

const Author = ({ row }: { row: TransactionRO }) => {
    switch (row.provider) {
        case TransactionSourceEnum.INSIDE:
            return <UserCell size="m" id={ row.userId } />;
        case TransactionSourceEnum.PAYPAL:
            return <React.Fragment>PayPal</React.Fragment>;

        default:
            return <React.Fragment>Stripe</React.Fragment>;
    }
};

export const TransactionsColumns = ({
    t,
    onCertificateOpenClick,
    onOrderOpenClick,
    currency,
    i18n,
}): TableColumns<TransactionRO> => [
    {
        header: t('date'),
        accessor: 'createdAt',
        sort: true,
        row: {
            title: ({ row }) =>
                formatDate(row.createdAt, { format: 'dd MMMM yyyy, HH:mm', lang: i18n.language }),
        },
    },
    {
        header: t('component.amount'),
        accessor: 'amount',
        row: {
            title: ({ row }) => (
                <React.Fragment>
                    <Amount
                        color={ row.amount < 0 ? 'negative' : 'positive' }
                        weight="bold"
                        type="decimal"
                        currency={ currency }
                        value={ row.amount }
                    />
                    { (row.order || row.certificatesale) && (
                        <IconButton
                            icon={ OpenS }
                            style={ { color: 'var(--color-text-secondary)' } }
                            onClick={ (e) => {
                                e.stopPropagation();
                                if (row.certificatesale) {
                                    onCertificateOpenClick(row.certificatesale.id);
                                } else {
                                    onOrderOpenClick(row.order.id);
                                }
                            } }
                        />
                    ) }
                </React.Fragment>
            ),
            // subtitle: ({ row }) =>
            //     (
            //         <React.Fragment>
            //             Balance <Amount type="decimal" color="secondary" currency={ currency } value={ row.balance } />
            //         </React.Fragment>
            //     ) as unknown as string,
        },
    },
    {
        header: t('component.cashbox'),
        accessor: 'cashbox',
        row: {
            title: ({ row }) => (
                <React.Fragment>
                    { row.cashbox?.title }

                    { row.paymentId && (
                        <Tooltip
                            trigger="click"
                            position="top"
                            content={ (
                                <CopyButton
                                    fullText={ `${row.paymentId}` }
                                    text={ `Stripe Id: ${row.paymentId}` }
                                />
                            ) }
                            dataTestId="test-id"
                        >
                            <IconButton
                                onClick={ (e) => {
                                    e.stopPropagation();
                                } }
                                view="ghost"
                                icon={ InfoMarkS }
                            />
                        </Tooltip>
                    ) }
                </React.Fragment>
            ),
        },
    },
    {
        header: t('financeitemId'),
        accessor: 'financeitem',
        row: {
            title: ({ row }) => {
                if ((row.order || row.certificatesale) && row.amount < 0) {
                    return t('Возврат');
                }

                return row.financeitem.title.includes('financeitem')
                    ? t(row.financeitem.title)
                    : row.financeitem.title;
            },
        },
    },
    {
        header: t('author'),
        accessor: 'userId',
        row: {
            title: ({ row }) => <Author row={ row } />,
        },
    },
    {
        header: t('Комментарий'),
        accessor: 'comment',
        width: '150px',
    },
];

import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AmountInput, Checkbox, FlexColumns, Input, Select,
} from '@alphakits/ui/dist';
import { QuestroomCancelationTypeEnum } from '@escapenavigator/types/dist/questroom/enum/questroom-cancelation-type.enum';
import { CreateRuleDto } from '@escapenavigator/types/dist/slot-rule/create-rule.dto';
import { PrepaymentTypeEnum } from '@escapenavigator/types/dist/slot/enum/prepayment-type.enum';
import { enumToOptions } from '@escapenavigator/utils/dist/enum-to-options';
import { FormikProps } from 'formik';
import { HintTooltip } from 'src/components/hint-tooltip';
import { ModalSection } from 'src/components/layout/modal-section';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getHoursLabel } from 'src/utils/get-hours-label';

type Rule = Partial<CreateRuleDto>;

type Props = {
    setFieldValue: FormikProps<Rule>['setFieldValue'];
    values: FormikProps<Rule>['values'];
    errors: FormikProps<Rule>['errors'];
    touched: FormikProps<Rule>['touched'];
    withTitle?: boolean;
};

export const BookingRuleForm: React.FC<Props> = ({
    setFieldValue,
    values,
    errors,
    touched,
    withTitle,
}) => {
    const { t } = useTranslation();
    const {
        profile: { currency },
    } = useCurrentUser();

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            { withTitle && (
                <Input
                    dataTestId="title"
                    label={ t('tables.title') }
                    value={ values.title }
                    error={ touched.title && errors.title }
                    onChange={ (e) => setFieldValue('title', e.target.value) }
                    block={ true }
                    hint={ t('tariffHint') }
                />
            ) }

            <ModalSection title={ t('Доступность бронирования') }>
                <AmountInput
                    dataTestId="blockingHours"
                    label={ t('Блокировать онлайн запись') }
                    value={ values.blockingHours }
                    suffix={ t('часов до начала игры') }
                    error={ touched.blockingHours && errors.blockingHours }
                    onChange={ (e, { value }) => setFieldValue('blockingHours', value) }
                    block={ true }
                />

                <AmountInput
                    dataTestId="minHoursForBooking"
                    label={ t('component.minHoursForBooking') }
                    value={ values.minHoursForBooking }
                    suffix={ t('часов до начала игры') }
                    error={ touched.minHoursForBooking && errors.minHoursForBooking }
                    onChange={ (e, { value }) => setFieldValue('minHoursForBooking', value) }
                    block={ true }
                />

                <Checkbox
                    label={ t('openSlotWhenUserInCalendar') }
                    align="start"
                    checked={ values.openSlotWhenUserInCalendar }
                    onChange={ (e, { checked }) =>
                        setFieldValue('openSlotWhenUserInCalendar', checked) }
                    block={ true }
                    addons={ <HintTooltip text={ t('openSlotWhenUserInCalendarDescription') } /> }
                />
            </ModalSection>

            <ModalSection title={ t('Предоплата онлайн') }>
                <Select
                    dataTestId="prepaymentType"
                    label={ t('Предоплата онлайн') }
                    options={ enumToOptions(PrepaymentTypeEnum, t, 'prepayment') }
                    onChange={ ({ selected }) => setFieldValue('prepaymentType', selected?.key) }
                    selected={ values.prepaymentType }
                    error={ touched.prepaymentType && errors.prepaymentType }
                    block={ true }
                />

                { PrepaymentTypeEnum.NO !== values.prepaymentType && (
                    <AmountInput
                        dataTestId="prepayment"
                        label={ t('component.amount') }
                        value={ values.prepayment }
                        type="decimal"
                        suffix={
                            PrepaymentTypeEnum.PERCENT === values.prepaymentType ? '%' : currency
                        }
                        error={ touched.prepayment && errors.prepayment }
                        onChange={ (e, { value }) => setFieldValue('prepayment', value) }
                        block={ true }
                    />
                ) }

                <Checkbox
                    label={ t('prepayForTourists') }
                    align="start"
                    checked={ values.prepayForTourists }
                    onChange={ (e, { checked }) => setFieldValue('prepayForTourists', checked) }
                    block={ true }
                    addons={ <HintTooltip text={ t('prepayForTouristsDescription') } /> }
                />
            </ModalSection>

            <ModalSection title={ t('Штрафы') }>
                <Select
                    label={ t('Правило отмены квеста не в сррок') }
                    options={ enumToOptions(QuestroomCancelationTypeEnum, t, 'cancelationRiles') }
                    selected={ values.cancelationRule }
                    onChange={ ({ selected }) => setFieldValue('cancelationRule', selected?.key) }
                    block={ true }
                />

                { QuestroomCancelationTypeEnum.NO !== values.cancelationRule && (
                    <AmountInput
                        dataTestId="minDaysForFreeCanceling"
                        label={ t('component.minDaysForFreeCanceling') }
                        value={ values.minHoursForFreeCanceling }
                        suffix={ getHoursLabel(values.minHoursForFreeCanceling, t) }
                        error={ touched.minHoursForFreeCanceling && errors.minHoursForFreeCanceling }
                        onChange={ (e, { value }) =>
                            setFieldValue('minHoursForFreeCanceling', value) }
                        block={ true }
                    />
                ) }
            </ModalSection>
        </FlexColumns>
    );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import {
    AmountInput,
    FlexColumns,
    ModalContext,
    Select,
    Textarea,
    useCrudFormRequests,
    useGetOne,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateTransactionDto } from '@escapenavigator/types/dist/transaction/create-transaction.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { OptionsType } from '@escapenavigator/utils/dist/convert-to-options';
import { TFunction } from 'i18next';
import { CashboxesSelect } from 'src/components/selects/cashbox-select';
import { useApi } from 'src/providers/api/context';
import { useCurrentUser } from 'src/providers/current-user/context';
import { getTransactionStatusText } from 'src/utils/get-transaction-status';

import { CertificatesaleModal } from '../certificatesale';
import { OrderModal } from '../order';

type Props = {
    recordId?: number;
    employeeId?: number;
    close: () => void;
    t: TFunction;
    cb?: () => void;
    partnerOptions?: OptionsType<number>;
    financeitemOptions?: OptionsType<number>;
};

export const TransactionModal: React.FC<Props> = ({
    recordId,
    employeeId,
    close,
    t,
    cb,
    partnerOptions = [],
    financeitemOptions = [],
}) => {
    const { transactions } = useApi();
    const {
        profile: { currency },
        current,
    } = useCurrentUser();
    const { openModal } = useContext(ModalContext);

    const openOrderModal = openModal(OrderModal, 'm', true);
    const openCertificatesaleModal = openModal(CertificatesaleModal, 'm', true);

    const { record, loading, error } = useGetOne(recordId, transactions.getOne);

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: transactions.create,
        saveCallback: cb,
        close,
    });

    const title = () => {
        if (record.id && record.financeitem.type) {
            return `${t(`options.financeItem.${record.financeitem.type}`)} №${
                record.id
            } ${getTransactionStatusText(record.type, t)}`;
        }

        return t('modalsHeader.transaction');
    };

    const isOrderOrCertificateTransaction = !!record?.certificatesale || !!record?.order;

    const submitButtonText = () => {
        if (record?.order?.id) {
            return t('openGame');
        }

        if (record?.certificatesale?.id) {
            return t('openCertificate');
        }

        return undefined;
    };

    const onOpenClick = async () => {
        if (record.order) {
            openOrderModal({ recordId: record.order.id });
        } else {
            openCertificatesaleModal({ certificatesaleId: record.certificatesale.id });
        }
    };

    return (
        <RestForm
            recordId={ +recordId }
            title={ title() }
            initialValues={ serializeRecord(
                CreateTransactionDto,
                record?.id
                    ? record
                    : {
                        employeeId,
                        userId: current.user.id,
                        paymentPeriod: '2024-07-01',
                    },
            ) }
            validate={ isOrderOrCertificateTransaction ? undefined : validateByDto(t) }
            save={ isOrderOrCertificateTransaction ? onOpenClick : save }
            submitButtonText={ submitButtonText() }
            error={ error }
            softError={ softError }
            loading={ loading }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, handleChange, setFieldValue, errors, touched,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    { !values.employeeId && !isOrderOrCertificateTransaction && (
                        <Select
                            dataTestId="financeitemId"
                            selected={ values?.financeitemId }
                            label={ t('component.selectFinanceitem') }
                            onChange={ ({ selected }) =>
                                setFieldValue('financeitemId', selected?.key) }
                            options={ financeitemOptions }
                            required={ true }
                            error={ touched.financeitemId && errors.financeitemId }
                            block={ true }
                            disabled={ !!recordId }
                            optionsListWidth="field"
                        />
                    ) }

                    <CashboxesSelect
                        selected={ values?.cashboxId }
                        label={ t('component.selectCashbox') }
                        onChange={ ({ selected }) => setFieldValue('cashboxId', selected?.key) }
                        type="all"
                        error={ touched.cashboxId && errors.cashboxId }
                        disabled={ !!recordId }
                    />

                    <AmountInput
                        dataTestId="amount"
                        label={ t('component.amount') }
                        suffix={ currency }
                        value={ values.amount }
                        onChange={ (e, { value }) => setFieldValue('amount', value) }
                        error={ touched.amount && errors.amount }
                        block={ true }
                        disabled={ !!recordId }
                        required={ true }
                        type="decimal"
                    />

                    { !values.employeeId && !isOrderOrCertificateTransaction && (
                        <Select
                            dataTestId="partnerId"
                            selected={ values?.partnerId }
                            label={ t('component.selectPartner') }
                            onChange={ ({ selected }) => setFieldValue('partnerId', selected?.key) }
                            options={ partnerOptions }
                            allowUnselect={ true }
                            error={ touched.partnerId && errors.partnerId }
                            block={ true }
                            disabled={ !!recordId }
                            optionsListWidth="field"
                        />
                    ) }

                    <Textarea
                        dataTestId="comment"
                        label={ t('Комментарий') }
                        value={ values.comment }
                        onChange={ handleChange('comment') }
                        block={ true }
                        maxLength={ 100 }
                        disabled={ !!recordId }
                        counter={ true }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};

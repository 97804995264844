/* eslint-disable @typescript-eslint/no-explicit-any */

import { SerializedTableSlot } from '../..';

const isIntercept = <T>(current: SerializedTableSlot<T>, arr: Array<SerializedTableSlot<T>>) =>
    arr.some(
        (slot) =>
            (current.start >= slot.start && current.start < slot.end) ||
            (current.end < slot.end && current.end > slot.start) ||
            (slot.end < current.end && slot.end > current.start) ||
            (slot.end < current.end && slot.end > current.start),
    );

export const getSlotOffset = <T>(
    slots: Array<SerializedTableSlot<T>>,
    currentIndex = 0,
): Array<SerializedTableSlot<T>> => {
    const newSlots = slots.map((slot, _, arr) => {
        const definedSlots = arr.filter((s) => s.indexOffset === currentIndex);

        if (!isIntercept(slot, definedSlots) && typeof slot.indexOffset === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            slot.indexOffset = currentIndex;
        }

        return slot;
    });

    return newSlots.some((slot) => typeof slot.indexOffset === 'undefined')
        ? getSlotOffset(newSlots, currentIndex + 1)
        : newSlots;
};

import React from 'react';
import { FlexColumns, Typography } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { SlotTemplateElement } from '@escapenavigator/types/dist/slot-template/slot-template-element';
import { ChangeTemplateSlotsFormDto } from '@escapenavigator/types/dist/slot/change-template-slots-form.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { TariffSelect } from 'src/components/tariff-select';
import { selectAllTariffs } from 'src/store';
import { useAppSelector } from 'src/store/hooks';
import { useRulesApi } from 'src/store/hooks/use-rules';

import { BookingRule } from '../booking-rules';

type Props = {
    close: () => void;
    t: TFunction;
    submit: (values: Pick<SlotTemplateElement, 'ruleId' | 'tariffId'>) => void;
    ruleId?: number;
    tariffId?: number;
};

export const EditTemplateSlotsModal: React.FC<Props> = ({
    close, t, submit, ruleId, tariffId,
}) => {
    const tariffs = useAppSelector(selectAllTariffs);
    const {
        rules, loading, updating, createRule,
    } = useRulesApi();

    const handleSubmut = async (values: ChangeTemplateSlotsFormDto) => {
        let { ruleId } = values;

        if (!ruleId) {
            const rule = await createRule({
                title: values.title,
                minHoursForBooking: values.minHoursForBooking,
                prepayForTourists: values.prepayForTourists,
                blockingHours: values.blockingHours,
                openSlotWhenUserInCalendar: values.openSlotWhenUserInCalendar,
                minHoursForFreeCanceling: values.minHoursForFreeCanceling,
                prepayment: values.prepayment,
                prepaymentType: values.prepaymentType,
                cancelationRule: values.cancelationRule,
                saved: values.saved,
            });

            if (!rule) return;

            ruleId = rule.id;
        }

        submit({
            tariffId: values.tariffId,
            ruleId,
        });
        close();
    };

    return (
        <RestForm
            title={ t('Автозаполнение') }
            loading={ loading || updating }
            save={ handleSubmut }
            validate={ validateByDto(t) }
            close={ close }
            t={ t }
            initialValues={ serializeRecord(ChangeTemplateSlotsFormDto, {
                ruleId: ruleId || rules[0]?.id,
                tariffId: tariffId || tariffs[0]?.id,
            }) }
        >
            { ({
                values, setFieldValue, touched, errors,
            }) => (
                <FlexColumns columns={ 1 } gr={ 32 }>
                    <FlexColumns columns={ 1 } gr={ 20 }>
                        <Typography.Title tag="div" view="xxsmall" weight="bold">
                            { t('Тариф') }
                        </Typography.Title>

                        <TariffSelect
                            value={ values.tariffId }
                            onChange={ (id) => setFieldValue('tariffId', id) }
                            error={ touched.tariffId && errors.tariffId }
                        />
                    </FlexColumns>

                    <BookingRule
                        values={ values }
                        setFieldValue={ setFieldValue }
                        errors={ errors }
                        touched={ touched }
                        rules={ rules || [] }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};

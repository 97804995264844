import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dot, FlexColumns, ModalContext, Skeleton, Table, Typography,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { UserRO } from '@escapenavigator/types/dist/user/crud/user.ro';
import { convertHHMMToMinutes } from '@escapenavigator/utils/dist';
import { MonthlyReport } from 'src/components/monthly-report';
import { UpdateUserSessionModal } from 'src/modals/user-session/update';
import { useApi } from 'src/providers/api/context';
import { getLanguage } from 'src/utils/get-language';

import { useSessionsColumns } from './columns';

type Props = {
    user: UserRO;
};

export const UserWorkingShifts: React.FC<Props> = ({ user }) => {
    const { i18n, t } = useTranslation();
    const { openModal } = useContext(ModalContext);
    const { users } = useApi();

    const { querySessionsData, querySessionsFetch, querySessionsLoading } = useApiMethod({
        api: users.querySessions,
    });

    const getSessions = useCallback(
        (query: { startDate: Date; endDate: Date }) => {
            querySessionsFetch({
                where: { userId: user.id, ...query },
                limit: 100,
                page: 1,
                sort: 'date',
                order: 'ASC',
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id],
    );

    const columns = useSessionsColumns({ t, i18n });

    const totalHours = useMemo(() => {
        if (!querySessionsData) return 0;

        const min = querySessionsData.items.reduce(
            (acc, shift) =>
                acc +
                shift.sessions.reduce(
                    (sAcc, session) =>
                        sAcc +
                        (convertHHMMToMinutes(session.end) - convertHHMMToMinutes(session.start)),
                    0,
                ),
            0,
        );

        return `${Math.floor(min / 60)}:${min % 60}`;
    }, [querySessionsData]);

    return (
        <FlexColumns columns={ 1 }>
            <MonthlyReport onChange={ getSessions }>
                <Skeleton visible={ querySessionsLoading }>
                    <Typography.Text view="title" weight="medium" color="secondary">
                        { t('часов') }{ ' ' }
                        <Typography.Text view="title" weight="medium" color="primary">
                            { totalHours } { user.hoursContract ? `/${user.hoursContract}` : '' }
                        </Typography.Text>
                    </Typography.Text>
                </Skeleton>

                <Dot color="grey" size="sm" />

                <Skeleton visible={ querySessionsLoading }>
                    <Typography.Text view="title" weight="medium" color="secondary">
                        { t('смен') }{ ' ' }
                        <Typography.Text view="title" weight="medium" color="primary">
                            { querySessionsData?.items.length || 0 }
                        </Typography.Text>
                    </Typography.Text>
                </Skeleton>
            </MonthlyReport>

            <Table.TableComponent
                hideSearch={ true }
                columns={ columns }
                loading={ querySessionsLoading }
                onRowClick={ (session, cb) => openModal(UpdateUserSessionModal)({ session, cb }) }
                language={ getLanguage(i18n) }
                records={ querySessionsData?.items || [] }
            />
        </FlexColumns>
    );
};

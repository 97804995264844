import { initClientApi } from '@escapenavigator/services/dist/api/init-client-api';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import {
    createAsyncThunk,
    createEntityAdapter,
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit';

import { RootState } from '../store';

const certificatesAdapter = createEntityAdapter<CertificateRO>();
const { certificates } = initClientApi(process.env.REACT_APP_API_DOMAIN);

export const fetchCertificates = createAsyncThunk<CertificateRO[]>(
    'certificates/fetchCertificates',
    async () => {
        const { data } = await certificates.query(undefined);

        return data;
    },
);

const certificatesSlice = createSlice({
    name: 'certificates',
    initialState: certificatesAdapter.getInitialState(),
    reducers: {
        upsertCertificate(state, action: PayloadAction<CertificateRO>) {
            certificatesAdapter.upsertOne(state, action.payload);
        },
        removeCertificate(state, action: PayloadAction<CertificateRO['id']>) {
            certificatesAdapter.removeOne(state, action.payload);
        },
    },
    extraReducers: (builders) => {
        builders.addCase(
            fetchCertificates.fulfilled,
            (state, action: PayloadAction<CertificateRO[]>) => {
                certificatesAdapter.upsertMany(state, action.payload);
            },
        );
    },
});

export const { selectAll: selectAllCertificates, selectById: selectCertificateById } =
    certificatesAdapter.getSelectors<RootState>((state) => state.certificates);

export const { upsertCertificate, removeCertificate } = certificatesSlice.actions;

export default certificatesSlice.reducer;

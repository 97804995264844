/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { InfoMarkS } from '@alphakits/icons';
import {
    Button, Divider, Flex, Tooltip, Typography,
} from '@alphakits/ui';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import classNames from 'classnames';
import { TFunction } from 'i18next';

import { HandlerKey } from '../../../../../components/quill/get-handlers';

import 'react-quill/dist/quill.snow.css';
import styles from './index.module.css';

type Props = {
    text: string;
    lang: Languages;
    error?: string;
    handlers: Array<{ title: string; key: HandlerKey; description: string }>;
    onChange: (value: string) => void;
};

function setValue(text: string) {
    const cursorPosition = this.quill.getSelection().index;

    this.quill.insertText(cursorPosition, text);
}

const Btn = ({
    text,
    action,
    description,
}: {
    text: string;
    action: string;
    description: string;
}) => (
    <Button view="secondary" size="xs" className={ classNames(action, styles.action) }>
        <Flex gap="xs">
            { text }

            <Tooltip
                trigger="hover"
                position="top"
                content={ (
                    <div style={ { width: 300, overflow: 'auto' } }>
                        <Typography.Text view="primary-medium">{ description }</Typography.Text>
                    </div>
                ) }
            >
                <InfoMarkS style={ { opacity: 0.4 } } />
            </Tooltip>
        </Flex>
    </Button>
);
const ifParams: HandlerKey[] = [
    'photos',
    'gameResult',
    'servicesList',
    'howToFindLocation',
    'importantInfo',
    'prepareInfo',
];

function pasteText(param: HandlerKey, t: TFunction, lng: Languages) {
    if (ifParams.includes(param)) {
        return `
{{#if ${param}}}
${t(`crm-crosssales:baseValues.${param}`, { value: `{${param}}`, lng })}
{{/if}}
`;
    }

    return `{${param}}`;
}

export const FeedbackTextarea: React.FC<Props> = ({
    onChange, text, handlers, lang, error,
}) => {
    const { t } = useTranslation();
    const modules = useMemo(() => {
        const handlersObject = {
            handlers: {},
        };

        handlers.forEach((handler) => {
            handlersObject.handlers[handler.key] = function () {
                setValue.call(this, pasteText(handler.key, t, lang));
            };
        });

        return {
            toolbar: {
                container: `#toolbar${lang}`,
                handlers: handlersObject.handlers,
            },
        };
    }, []);

    const handleChange = (txt: string) => {
        onChange(txt);
    };

    return (
        <div className={ styles.wrap }>
            <div id={ `toolbar${lang}` } className={ styles.toolbar }>
                <Flex gap="xs" justify="start">
                    <button type="button" className="ql-link" />
                    <button type="button" className="ql-bold" />
                    <button type="button" className="ql-clean" />
                </Flex>

                <Divider size="m" />

                <Flex gap="xs" justify="start" wrap={ true }>
                    { handlers.map((h) => (
                        <Btn
                            key={ h.key }
                            description={ h.description }
                            text={ h.title }
                            action={ `ql-${h.key}` }
                        />
                    )) }
                </Flex>
            </div>

            <ReactQuill
                className={ styles.editor }
                value={ text }
                onChange={ handleChange }
                theme="snow"
                modules={ modules }
            />
            { error && (
                <Typography.Text view="caps" color="negative">
                    { error }
                </Typography.Text>
            ) }
        </div>
    );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanel, Tabs } from 'src/modals/components/tabs';

import { NavigatorCertificates } from './navigator';
import { UploadedCertificates } from './uploaded';

export const CertificateSalesPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Tabs view="header" defaultActive="Navigator">
            <TabPanel name="Navigator">
                <NavigatorCertificates />
            </TabPanel>

            <TabPanel name={ t('Uploaded') }>
                <UploadedCertificates />
            </TabPanel>
        </Tabs>
    );
};

import React, { useContext } from 'react';
import { OpenS, TrashS } from '@alphakits/icons';
import {
    Button,
    Flex,
    FlexColumns,
    IconButton,
    ModalContext,
    SidepanelHeader,
    Status,
} from '@alphakits/ui/dist';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { format } from 'date-fns';
import { CertificateData } from 'src/utils/get-certificate-data';

import { OrderModal } from '../order';

type Props = {
    certificatesale: CertificateSaleRO;
    annul: (id: number) => void;
    certifiateData: CertificateData;
};

export const CertificatesaleModalHeder: React.FC<Props> = ({
    certificatesale,
    annul,
    certifiateData,
}) => {
    const { openModal } = useContext(ModalContext);

    const openOrderModal = (orderId: number) =>
        openModal(OrderModal, 'm', true)({ recordId: orderId });

    return (
        <FlexColumns columns={ 1 } gr={ 16 }>
            <SidepanelHeader
                title={ certificatesale.certificate.title }
                bottomAddons={ (
                    <Flex justify="start" gap="sm">
                        <Status color={ certifiateData.color } view="contrast">
                            { certifiateData.title }{ ' ' }
                            { !!certificatesale.order &&
                                format(
                                    new Date(certificatesale.order.createdAt),
                                    'dd MMMM yyyy, HH:mm',
                                ) }
                        </Status>

                        { certificatesale.order && (
                            <IconButton
                                size="s"
                                view="secondary"
                                icon={ OpenS }
                                onClick={ () => openOrderModal(certificatesale.order.orderId) }
                            />
                        ) }
                    </Flex>
                ) }
                rightAddons={
                    !certificatesale.order &&
                    !certificatesale.annul && (
                        <Button
                            view="outlined"
                            size="xs"
                            leftAddons={ <TrashS /> }
                            onClick={ () => annul(certificatesale.id) }
                        />
                    )
                }
            />
        </FlexColumns>
    );
};

import React from 'react';
import { InfoMarkS } from '@alphakits/icons';
import {
    Amount, CopyButton, formatDate, IconButton, TagsList, Tooltip,
} from '@alphakits/ui/dist';
import { TableColumns } from '@alphakits/ui/dist/table/types';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { PromocodeTypeEnum } from '@escapenavigator/types/dist/promocode/emun/promocode-type.enum';
import { PromocodeRO } from '@escapenavigator/types/dist/promocode/promocode.ro';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { TFunction } from 'i18next';
import { PromocodeDetails } from 'src/components/details/promocode-details';

type Props = {
    questrooms: QuestroomRO[];
    t: TFunction;
    currency: ProfileCurrencyEnum;
};

export const getPromocodeApplyingsRule = (promocode: PromocodeRO, t: TFunction) => {
    if (!promocode.multiple) return t('Одноразовый');
    if (promocode.multiple && !promocode.availableApplyings) return t('Многоразовый');

    return t('применений промокода', {
        count: promocode.availableApplyings,
        value: promocode.applyings || 0,
    });
};

export const PromocodeColumns = ({ questrooms, t, currency }: Props): TableColumns<PromocodeRO> => [
    {
        header: t('Промокод'),
        accessor: 'code',
        row: {
            title: ({ row }) => <CopyButton text={ row.code } />,
            subtitle: ({ row }) => `${t('Кол-во применений')}: ${row.applyings}`,
        },
    },

    {
        header: t('tables.quest'),
        accessor: 'questroomsIds',
        row: {
            title: ({ row }) => {
                if (row.allQuestrooms) return 'All';

                const questroomTitles = questrooms
                    .filter((q) => row.questroomsIds.includes(q.id))
                    .map((q) => q.title);

                return (
                    <Tooltip
                        trigger="click"
                        position="top"
                        content={ (
                            <div style={ { maxWidth: 400 } }>
                                <TagsList tags={ questroomTitles } />
                            </div>
                        ) }
                        dataTestId="test-id"
                    >
                        <IconButton
                            onClick={ (e) => {
                                e.stopPropagation();
                            } }
                            view="ghost"
                            icon={ InfoMarkS }
                        />
                    </Tooltip>
                );
            },
        },
    },
    {
        header: t('Скидка'),
        accessor: 'discount',
        row: {
            title: ({ row }) => (
                <Amount
                    type="decimal"
                    value={ row.discount }
                    currency={ PromocodeTypeEnum.FIXED ? currency : '%' }
                />
            ),
            subtitle: ({ row }) => t(`options.promocodes.${row.type}`),
        },
    },
    {
        header: t('tables.validity'),
        accessor: 'validFrom',
        row: {
            title: ({ row }) => {
                const from = row.validFrom
                    ? `${t('from')} ${formatDate(row.validFrom, { format: 'dd.MM.yyyy' })}`
                    : '';
                const to = row.validTo
                    ? `${t('to')} ${formatDate(row.validTo, { format: 'dd.MM.yyyy' })}`
                    : '';

                return from || to ? `${from} ${to}` : t('Бессрочно');
            },
            subtitle: ({ row }) => getPromocodeApplyingsRule(row, t),
        },
    },

    {
        header: t('tables.description'),
        accessor: 'description',
        row: {
            title: ({ row }) =>
                row.description && (
                    <Tooltip
                        trigger="click"
                        position="bottom-end"
                        content={ (
                            <div style={ { width: 300 } }>
                                <PromocodeDetails promocode={ row } />
                            </div>
                        ) }
                        dataTestId="test-id"
                    >
                        <IconButton
                            onClick={ (e) => {
                                e.stopPropagation();
                            } }
                            view="ghost"
                            icon={ InfoMarkS }
                        />
                    </Tooltip>
                ),
        },
    },
];

import React, { useEffect } from 'react';
import { FlexColumns, RadioGroup, useCrudFormRequests } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CreateGoogleCalendarDto } from '@escapenavigator/types/dist/google-calendar/create-google-calendar.dto';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { LocationsPicker } from 'src/components/locations-picker';
import { useApi } from 'src/providers/api/context';

type Props = {
    clientSecret: string;
    close: () => void;
    cb: () => void;
    t: TFunction;
};

export const GoogleCalendarCreateModal: React.FC<Props> = ({
    clientSecret, close, cb, t,
}) => {
    const { googleCalendarsApi } = useApi();

    const {
        getInitialData, getInitialFetch, getInitialLoading, getInitialError,
    } = useApiMethod({
        api: googleCalendarsApi.getInitial,
    });

    useEffect(() => {
        getInitialFetch({ clientSecret });

        window.history.replaceState({}, null, window.location.href.split('?')[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        save,
        updating,
        error: softError,
    } = useCrudFormRequests({
        saveRequest: googleCalendarsApi.create,
        saveCallback: cb,
        close,
    });

    return (
        <RestForm
            title="Google calendar integration"
            initialValues={ serializeRecord(CreateGoogleCalendarDto, {
                title: getInitialData?.length === 1 ? getInitialData[0].title : '',
                token: getInitialData?.length === 1 ? getInitialData[0].token : '',
                calendarId: getInitialData?.length === 1 ? getInitialData[0].calendarId : '',
                allLocations: true,
            }) }
            validate={ validateByDto(t) }
            loading={ getInitialLoading }
            error={ getInitialError?.message }
            softError={ softError }
            save={ save }
            updating={ updating }
            close={ close }
            t={ t }
        >
            { ({
                values, errors, touched, setFieldValue, setValues,
            }) => (
                <FlexColumns columns={ 1 } gr={ 24 }>
                    { (getInitialData?.length > 1 || !values.calendarId) && (
                        <RadioGroup
                            title={ t('googleCalendarChoose') }
                            data={ getInitialData?.map((item) => ({
                                key: item.calendarId,
                                content: item.title,
                            })) }
                            selected={ values.calendarId }
                            gr={ 8 }
                            error={ touched.calendarId && errors.calendarId }
                            onChange={ (selected) => {
                                const calendar = getInitialData.find(
                                    (item) => item.calendarId === selected,
                                );

                                setFieldValue('calendarId', calendar.calendarId);
                                setFieldValue('title', calendar.title);
                                setFieldValue('token', calendar.token);
                            } }
                        />
                    ) }

                    <LocationsPicker
                        error={ !!errors.locationIds }
                        title={ t('googleCalendarAllLocations') }
                        allLocations={ values.allLocations }
                        locationIds={ values.locationIds }
                        onChange={ (value) => setValues({ ...values, ...value }) }
                    />
                </FlexColumns>
            ) }
        </RestForm>
    );
};

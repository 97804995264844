import React from 'react';
import {
    Checkbox,
    CheckboxGroup,
    convertToOptions,
    FlexColumns,
    Input,
    Select,
    Typography,
    useCrudFormRequests,
} from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { CreateWidgetDto } from '@escapenavigator/types/dist/widget/create-widget.dto';
import { WidgetTypeEnum } from '@escapenavigator/types/dist/widget/enum/widget-type.enum';
import { WidgetRO } from '@escapenavigator/types/dist/widget/widget.ro';
import { enumToOptions, serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { LocationsPicker } from 'src/components/locations-picker';
import { QuestroomsSelect } from 'src/components/selects/questrooms-select';
import { useApi } from 'src/providers/api/context';
import { selectAllCertificates, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

type Props = {
    record?: WidgetRO;
    type?: WidgetTypeEnum;
    close: () => void;
    refetch: () => void;
    t: TFunction;
};

export const WidgetModal: React.FC<Props> = ({
    type, record, close, refetch, t,
}) => {
    const { widgets } = useApi();
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));
    const certificates = useAppSelector(selectAllCertificates) || [];

    const recordId = record?.id;

    const {
        save,
        remove,
        removing,
        updating,
        error: softError,
    } = useCrudFormRequests({
        recordId,
        saveRequest: recordId ? widgets.update : widgets.create,
        removeRequest: widgets.remove,
        saveCallback: () => refetch(),
        removeCallback: () => refetch(),
        close,
    });

    return (
        <RestForm
            recordId={ +recordId }
            title={ record?.type ? t(`options.widgetType.${record.type}`) : t('modalsHeader.widget') }
            initialValues={ serializeRecord(CreateWidgetDto, record || { type }) }
            validate={ validateByDto(t) }
            save={ save }
            remove={ remove }
            softError={ softError }
            updating={ updating || removing }
            close={ close }
            t={ t }
        >
            { ({
                values, setFieldValue, errors, touched, handleChange, setValues,
            }) => {
                const showIdsForOneQuestroom = values.type === WidgetTypeEnum.ONE_QUESTROOM;

                const showidsForAllQuestrooms =
                    values.type === WidgetTypeEnum.ALL_QUESTROOM ||
                    values.type === WidgetTypeEnum.WIDGET_BUTTON;

                const showIdsForAllCertificates =
                    values.type === WidgetTypeEnum.ALL_CERTIFICATES ||
                    values.type === WidgetTypeEnum.WIDGET_BUTTON;

                return (
                    <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <Select
                                block={ true }
                                label={ t('component.widgetType') }
                                disabled={ !!record?.id }
                                options={ enumToOptions(WidgetTypeEnum, t, 'widgetType') }
                                selected={ values.type }
                                allowUnselect={ true }
                                onChange={ ({ selected }) => {
                                    setFieldValue('type', selected?.key);
                                } }
                                optionsListWidth="field"
                            />

                            <Input
                                dataTestId="URL"
                                label="URL"
                                value={ values.url }
                                error={ touched.url && errors.url }
                                onChange={ handleChange('url') }
                                block={ true }
                                hint={ t('Укажите конечный адрес страницы') }
                                required={ true }
                            />

                            { showIdsForOneQuestroom && (
                                <QuestroomsSelect
                                    questrooms={ questrooms }
                                    label={ t('Выберите квест') }
                                    error={ touched.questroomId && errors.questroomId }
                                    multiple={ false }
                                    selected={ values.questroomId }
                                    onChange={ ({ selected }) =>
                                        setFieldValue('questroomId', selected?.key) }
                                />
                            ) }

                            { showidsForAllQuestrooms && (
                                <LocationsPicker
                                    error={ !!errors.locationIds }
                                    allLocations={ values.allLocations }
                                    locationIds={ values.locationIds }
                                    onChange={ (value) => setValues({ ...values, ...value }) }
                                />
                            ) }

                            { showIdsForAllCertificates && (
                                <React.Fragment>
                                    <Typography.Text weight="bold" view="title">
                                        { t('Сертификаты') }
                                    </Typography.Text>

                                    <Checkbox
                                        label={ t('widget-guide:guide.all_certificates.all') }
                                        checked={ values.allCertificates }
                                        onChange={ (e, { checked }) =>
                                            setFieldValue('allCertificates', checked) }
                                    />

                                    { !values.allCertificates && (
                                        <React.Fragment>
                                            { certificates.length ? (
                                                <CheckboxGroup
                                                    selected={ values.certificateIds }
                                                    data={ convertToOptions(certificates) }
                                                    onChange={ (v) =>
                                                        setFieldValue('certificateIds', v) }
                                                />
                                            ) : (
                                                <Typography.Text view="caps" color="secondary">
                                                    { t('У вас пока нет сертификатов') }
                                                </Typography.Text>
                                            ) }
                                        </React.Fragment>
                                    ) }

                                    { touched.certificateIds && errors.certificateIds && (
                                        <Typography.Text view="title" color="negative">
                                            { errors.certificateIds }
                                        </Typography.Text>
                                    ) }
                                </React.Fragment>
                            ) }
                        </FlexColumns>
                        <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                            <FlexColumns columns={ 1 } gr={ 8 } gc={ 0 }>
                                <Typography.Title
                                    tag="div"
                                    defaultMargins={ true }
                                    view="xxsmall"
                                    weight="bold"
                                >
                                    Google Analytics
                                </Typography.Title>

                                <Typography.Text view="primary-small" color="secondary">
                                    { t('googleAnal.descriptionWidget') }
                                </Typography.Text>
                            </FlexColumns>

                            <Input
                                dataTestId="ga4Id"
                                label="Measurement ID"
                                value={ values.ga4Id }
                                error={ touched.ga4Id && errors.ga4Id }
                                onChange={ handleChange('ga4Id') }
                                block={ true }
                            />
                            <Input
                                dataTestId="gaCoversationId"
                                label="AW-CONVERSION_ID"
                                value={ values.gaCoversationId }
                                error={ touched.gaCoversationId && errors.gaCoversationId }
                                onChange={ handleChange('gaCoversationId') }
                                block={ true }
                            />
                            <Input
                                dataTestId="gaCoversationLabel"
                                label="CONVERSION_LABEL"
                                error={ touched.gaCoversationLabel && errors.gaCoversationLabel }
                                value={ values.gaCoversationLabel }
                                onChange={ handleChange('gaCoversationLabel') }
                                block={ true }
                            />
                        </FlexColumns>
                    </FlexColumns>
                );
            } }
        </RestForm>
    );
};

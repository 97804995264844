import React from 'react';
import { AmountInput, FlexColumns, Textarea } from '@alphakits/ui/dist';
import { RestForm } from '@alphakits/ui/dist/form/templates/rest-form';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { CreateCertificatesaleDiscountDto } from '@escapenavigator/types/dist/certificate-sale/create-certificatesale-discount.dto';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { serializeRecord, validateByDto } from '@escapenavigator/utils/dist';
import { TFunction } from 'i18next';
import { useApi } from 'src/providers/api/context';

type Props = {
    t: TFunction;
    close: () => void;
    setCerificate: (values: CertificateSaleRO) => void;
    certificateId: number;
    currency: ProfileCurrencyEnum;
    amount?: number;
};

export const AddHandDiscount: React.FC<Props> = ({
    close,
    t,
    certificateId,
    setCerificate,
    currency,
    amount = 0,
}) => {
    const { certificatesaleDiscounts } = useApi();

    const { createFetch, createLoading, createError } = useApiMethod({
        api: certificatesaleDiscounts.create,
        successCallback: ({ data }) => {
            setCerificate(data);
            close();
        },
    });

    return (
        <RestForm
            t={ t }
            title={ t('Ручная скидка') }
            initialValues={ serializeRecord(CreateCertificatesaleDiscountDto, {
                certificatesaleId: certificateId,
                amount,
            }) }
            validate={ validateByDto(t) }
            loading={ false }
            updating={ createLoading }
            removing={ false }
            close={ close }
            save={ async (data) => {
                createFetch({ data });
            } }
        >
            { ({
                values, handleChange, errors, touched, setFieldValue,
            }) => (
                <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 }>
                    <AmountInput
                        value={ values.amount }
                        suffix={ currency }
                        error={ touched.amount && errors.amount }
                        onChange={ (e, { value }) => setFieldValue('amount', value) }
                        label={ t('Укажите значение ручной скидки') }
                        required={ true }
                        block={ true }
                        type="decimal"
                    />

                    <Textarea
                        value={ values.reason }
                        maxLength={ 100 }
                        counter={ true }
                        required={ true }
                        error={ touched.reason && errors.reason }
                        onChange={ handleChange('reason') }
                        label={ t('Причина') }
                        block={ true }
                    />

                    { createError }
                </FlexColumns>
            ) }
        </RestForm>
    );
};

import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BookM, EditM, MapTagM, PhoneM, PlanetM, PlusM, SearchM,
} from '@alphakits/icons';
import {
    Button,
    Cell,
    Collapse,
    Flex,
    FlexColumns,
    Image,
    ModalContext,
    PageWithControls,
    Tag,
    Typography,
} from '@alphakits/ui/dist';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { Section } from 'src/components/layout/section';
import { LocationModal } from 'src/modals/location';
import { LocationLocalizationModal } from 'src/modals/location/location-localization';
import { QuestroomModal } from 'src/modals/questroom';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

import { QuestroomCell } from './questroom-cell';

const LocaleCell = ({ location }: { location: LocationRO }) => {
    const { t } = useTranslation();

    const {
        profile: { availableLanguages, language },
    } = useCurrentUser();

    const [currentLocale, setCurrentLocale] = useState<Languages>(language);

    const locales = availableLanguages.reduce((acc, locale) => {
        const isDefault = locale === language;

        acc[locale] = {
            howToFind: isDefault
                ? location?.howToFind
                : location.locales.find((l) => l.language === locale)?.howToFind,
            prepareText: isDefault
                ? location?.prepareText
                : location.locales.find((l) => l.language === locale)?.prepareText,
        };

        return acc;
    }, {} as Record<string, { prepareText: string; howToFind: string }>);

    return (
        <FlexColumns columns={ 1 } gr={ 20 }>
            <Collapse collapsedLabel={ t('Location info') } expandedLabel="Hide">
                <FlexColumns columns={ 1 } gr={ 12 }>
                    <Flex gap="sm" justify="start">
                        { availableLanguages.map((l) => (
                            <Tag
                                size="s"
                                view={ l === currentLocale ? 'primary-inverted' : 'primary' }
                                onClick={ () => setCurrentLocale(l) }
                                text={ l.toUpperCase() }
                            />
                        )) }
                    </Flex>

                    <Cell.Base
                        addon={ <Image icon={ <MapTagM /> } /> }
                        title={ t('address') }
                        subtitle={ location.address }
                    />
                    <Cell.Base
                        title={ t('phone') }
                        subtitle={ location.phone }
                        addon={ <Image icon={ <PhoneM /> } /> }
                    />
                    <Cell.Base
                        title={ t('component.howToFind') }
                        subtitle={ locales[currentLocale]?.howToFind || t('Нет данных') }
                        addon={ <Image icon={ <SearchM /> } /> }
                    />

                    <Cell.Base
                        title={ t('Что важно знать перед бронированием') }
                        subtitle={ locales[currentLocale]?.prepareText || t('Нет данных') }
                        addon={ <Image icon={ <BookM /> } /> }
                    />
                </FlexColumns>
            </Collapse>
        </FlexColumns>
    );
};

export const QuestroomsSettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const { openModal } = useContext(ModalContext);

    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false }));

    const locations = useAppSelector(selectAllLocations);

    const openLocationModal = (location?: LocationRO) =>
        openModal(LocationModal)({
            location,
        });

    const locationsWithQuestrooms = useMemo(
        () =>
            locations.map((l) => ({
                ...l,
                questrooms: questrooms.filter((q) => l.id === q.locationId && !q.closed),
                closedQuesrooms: questrooms.filter((q) => l.id === q.locationId && q.closed),
            })),
        [locations, questrooms],
    );

    return (
        <FlexColumns columns={ 1 } gr={ 32 }>
            <div>
                <Button view="primary" size="s" onClick={ () => openLocationModal() }>
                    { t('Добавить локацию') }
                </Button>
            </div>

            { locationsWithQuestrooms.map((location) => (
                <Section subtitle={ location.address } title={ location.title }>
                    <PageWithControls
                        buttons={ [
                            {
                                leftAddons: <EditM />,
                                text: t('Редактировать локацию'),
                                onClick: () => openLocationModal(location),
                            },
                            {
                                leftAddons: <PlanetM />,
                                text: t('Локализация'),
                                onClick: () => openModal(LocationLocalizationModal)({ location }),
                            },
                            {
                                leftAddons: <PlusM />,
                                text: t('addQuestroom'),
                                onClick: () =>
                                    openModal(QuestroomModal)({ locationId: location.id }),
                            },
                        ] }
                    >
                        { !location.questrooms.length && (
                            <FlexColumns columns={ 1 } gr={ 16 }>
                                <Typography.Text view="title" color="secondary" weight="medium">
                                    No Escape rooms
                                </Typography.Text>
                                <div>
                                    <Button
                                        view="primary"
                                        size="s"
                                        onClick={ () =>
                                            openModal(QuestroomModal)({ locationId: location.id }) }
                                    >
                                        { t('addQuestroom') }
                                    </Button>
                                </div>
                            </FlexColumns>
                        ) }

                        { (!!location.questrooms.length || !!location.closedQuesrooms.length) && (
                            <FlexColumns columns={ 4 }>
                                { location.questrooms.map((questroom) => (
                                    <QuestroomCell questroom={ questroom } />
                                )) }

                                { location.closedQuesrooms.map((questroom) => (
                                    <QuestroomCell questroom={ questroom } />
                                )) }
                            </FlexColumns>
                        ) }

                        <LocaleCell location={ location } />
                    </PageWithControls>
                </Section>
            )) }
            <br />
            <br />
            <br />
            <br />
        </FlexColumns>
    );
};

// { /* // <FlexColumns columns={ 1 } gr={ 16 } gc={ 0 } className={ styles.content }>
//             //     { locationsWithQuestrooms.map((location) => (
//             //         <Box rounded="sm" border={ true } padding="md">
//             //             <CollapsableRow
//             //                 label=""
//             //                 expanded={ true }
//             //                 key={ location.id }
//             //                 content={ (
//             //                     <React.Fragment>
//             //                         <Content
//             //                             location={ location }
//             //                             onClick={ () => openLocationModal(location) }
//             //                         />
//             //                     </React.Fragment>
//             //                 ) }
//             //             >

//             //                 <br />
//             //             </CollapsableRow>
//             //         </Box>
//             //     )) }
//             // </FlexColumns> */ }

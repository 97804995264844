import React, { useEffect, useState } from 'react';
import { THEME, useTheme } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { currencyByCountry } from '@escapenavigator/types/dist/constants/currency-by-country';
import { CrmVerificationEnum } from '@escapenavigator/types/dist/profile/enum/crm-verifiaction.enum';
import { ProfileCurrencyEnum } from '@escapenavigator/types/dist/profile/enum/profile-currency';
import { ProfileStatusEnum } from '@escapenavigator/types/dist/profile/enum/profile-step.enum';
import { ProfileSubscriptionTypeEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription-type.enum';
import { ProfileSubscriptionEnum } from '@escapenavigator/types/dist/profile/enum/profile-subscription.enum';
import { ProfileRO } from '@escapenavigator/types/dist/profile/profile.ro';
import { CurrentUserRO } from '@escapenavigator/types/dist/user/current/current-user.ro';
import { LayoutLoader } from 'src/components/layout/layout-loader';
import { useRrewb } from 'src/hooks/use-rrweb';
import { useApi } from 'src/providers/api/context';

export type SubscriptionStatus = 'active' | 'paused' | 'none' | 'not_payed' | 'trialing';

type Context = {
    current?: CurrentUserRO;
    pro?: boolean;
    profile?: ProfileRO & { currency: ProfileCurrencyEnum };
    setUser?: (user: CurrentUserRO) => void;
    setProfile?: (profile: ProfileRO) => void;
};

export const CurrentUserContext = React.createContext<Context>({});

export const CurrentUserProvider = ({ children }) => {
    const { users, profiles } = useApi();
    const [current, setCurrent] = useState<CurrentUserRO>();
    const [profile, setProfile] = useState<ProfileRO>();
    const { startRecording } = useRrewb();
    const { setTheme } = useTheme();

    const { meFetch, meLoading, meError } = useApiMethod({
        api: users.me,
        successCallback: ({ data }) => {
            setTheme(data.user.theme === 'dark' ? THEME.DARK : THEME.LIGHT);
            setCurrent(data);
        },
    });

    const updateProfile = (data: ProfileRO) => {
        setProfile(data);
    };

    const {
        currentData, currentFetch, currentLoading, currentError,
    } = useApiMethod({
        api: profiles.current,
        successCallback: ({ data }) => {
            updateProfile(data);
            if (data.status !== ProfileStatusEnum.VERIFIED) {
                startRecording('verificationSteps');

                return;
            }

            if (
                data.status === ProfileStatusEnum.VERIFIED &&
                data.subscriptionType === ProfileSubscriptionTypeEnum.NONE
            ) {
                startRecording('verificatedZone');

                return;
            }

            if (
                data.status === ProfileStatusEnum.VERIFIED &&
                data.crmVerification !== CrmVerificationEnum.SUCCEED &&
                data.subscriptionType !== ProfileSubscriptionTypeEnum.NONE
            ) {
                startRecording('onboardingZone');
            }
        },
    });

    useEffect(() => {
        meFetch(undefined);
        currentFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (meError || currentError) {
            window.location.assign(`${process.env.REACT_APP_AUTH_DOMAIN}/`);
        }
    }, [meError, currentError]);

    if (meLoading || currentLoading || !currentData || !current) return <LayoutLoader />;

    return (
        <CurrentUserContext.Provider
            value={ {
                current,
                pro:
                    profile.subscriptionStatus === ProfileSubscriptionEnum.TRAILING ||
                    (profile.subscriptionType === ProfileSubscriptionTypeEnum.PRO &&
                        profile.subscriptionStatus === ProfileSubscriptionEnum.ACTIVE),
                profile: { ...profile, currency: currencyByCountry[currentData.country] },
                setUser: setCurrent,
                setProfile,
            } }
        >
            { children }
        </CurrentUserContext.Provider>
    );
};

export const useCurrentUser = () => React.useContext(CurrentUserContext);

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    convertToOptions, Divider, FlexColumns, Select, Sidepanel,
} from '@alphakits/ui';
import { currencyByCountry } from '@escapenavigator/types/dist/constants/currency-by-country';
import { languageNameByLanguage } from '@escapenavigator/types/dist/constants/language-name-by-language';
import { SendEmailRequestParams } from '@escapenavigator/types/dist/emails';
import { LocationRO } from '@escapenavigator/types/dist/location/location.ro';
import { CrossSaleDiscountTypeEnum } from '@escapenavigator/types/dist/profile/cross-sale/crossale-discount-type.enum';
import { QuestroomRO } from '@escapenavigator/types/dist/questroom/questroom.ro';
import { Languages } from '@escapenavigator/types/dist/shared/enum/languages.enum';
import { enumToOptions } from '@escapenavigator/utils/dist';
import { formatAmount } from '@escapenavigator/utils/dist/format-amount';
import { getEmailUpsalesSection } from '@escapenavigator/utils/dist/get-email-upsales-section';
import { transformText } from '@escapenavigator/utils/dist/transform-text';
import { addDays, format } from 'date-fns';
import { ViewEmail } from 'src/components/view-email';
import { useCurrentUser } from 'src/providers/current-user/context';
import { selectAllLocations, selectAllOptions, selectAllQuestrooms } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

const PHOTO =
    'https://escapenavigator-images.s3.eu-central-1.amazonaws.com/inside/other/Vwb0wrM50XY.jpg';

type Props = {
    subtitile: string;
    record: any;
};

export const EmailExampleModal: React.FC<Props> = ({ subtitile, record }) => {
    const { t } = useTranslation();
    const { profile } = useCurrentUser();

    const options = useAppSelector(selectAllOptions);

    const locations = useAppSelector(selectAllLocations);
    const questrooms = useAppSelector(selectAllQuestrooms({ deleted: false, closed: false }));

    const [selectedLocation, setSelectedLocation] = useState<LocationRO>(locations[0]);

    const locationQuestrooms = questrooms.filter((q) => q.locationId === selectedLocation.id);
    const [selectedQuestroom, setSelectedQuestroom] = useState<QuestroomRO>(locationQuestrooms[0]);

    const feetForUpsaeOptions = useMemo(
        () =>
            options.filter(
                (o) =>
                    !o.flexiblePrice &&
                    o.crossSale &&
                    (o.allQuestrooms || o.questroomsIds.includes(selectedQuestroom.id)),
            ),
        [options, selectedQuestroom],
    );

    const [locale, setLocale] = useState<Languages>(profile.language);

    const recordLocale = record.locales.find((l) => l.language === locale);

    const testData = useMemo(() => {
        const questroomLocale =
            selectedQuestroom?.locales?.find((l) => l.language === locale) || selectedQuestroom;
        const locationLocales =
            selectedLocation?.locales?.find((l) => l.language === locale) || selectedLocation;
        const website = selectedLocation?.site || profile.site;

        return {
            questroomTitle: questroomLocale?.title,
            clientName: 'John',
            howToFindLocation:
                locationLocales?.howToFind ||
                'ADD TEXT "HOW TO FIND LOCATION" IN LOCATION SETTINGS',
            importantInfo:
                questroomLocale?.importantInfo ||
                'ADD TEXT "IMPORTANT INFO" IN ESCAPE ROOM SETTINGS',
            prepareInfo:
                locationLocales?.prepareText || 'ADD TEXT "PREPARE INFO" IN LOCATION SETTINGS',
            address: selectedLocation?.address,
            phone: selectedLocation?.phone,
            servicesList:
                feetForUpsaeOptions?.length &&
                getEmailUpsalesSection({
                    upsales: feetForUpsaeOptions,
                    buttonText: 'Add now',
                    link: '#',
                }),
            date: '12.02.2025 14:30',
            website: `<a target="_blank" href="${website}">${website}</a>`,
            promocode: 'SDSC24SD',
            gameResult: '48',
            photos: `<p><a target="_blank" href="${PHOTO}" style="padding-top: 12px; padding-right: 12px; outline: none; border: none"><img src="${PHOTO}" style="width: 150px;height:150px; object-fit: cover;border-radius: 12px;"></a>`,
            validity: format(addDays(new Date(), record.discountValidityDays || 30), 'dd.MM.yyyy'),
            discount: formatAmount(
                record?.discount || 0,
                record?.discountType === CrossSaleDiscountTypeEnum.FIXED
                    ? currencyByCountry[profile.country]
                    : '%',
            ),
        };
    }, [selectedLocation, selectedQuestroom, profile, record, locale, feetForUpsaeOptions]);

    const sections: SendEmailRequestParams['data']['sections'] = [
        {
            type: 'paragraph',
            text: transformText(recordLocale.feedbackText, testData),
        },
    ];

    return (
        <Sidepanel title={ t('Пример') } subtitle={ `${subtitile}, ${languageNameByLanguage[locale]}` }>
            <FlexColumns gr={ 32 } columns={ 1 }>
                <FlexColumns columns={ 3 }>
                    <Select
                        label={ t('language') }
                        options={ enumToOptions(profile.availableLanguages) }
                        required={ true }
                        selected={ locale }
                        onChange={ ({ selected }) => setLocale(selected?.key as Languages) }
                        block={ true }
                        optionsListWidth="field"
                    />

                    <Select
                        label={ t('address') }
                        options={ convertToOptions(locations) }
                        required={ true }
                        selected={ selectedLocation.id }
                        onChange={ ({ selected }) => {
                            setSelectedLocation(locations.find((l) => l.id === selected.key));
                            setSelectedQuestroom(
                                questrooms.find((l) => l.locationId === selected.key),
                            );
                        } }
                        block={ true }
                        optionsListWidth="field"
                    />

                    <Select
                        label={ t('tables.quest') }
                        options={ convertToOptions(locationQuestrooms) }
                        required={ true }
                        selected={ selectedQuestroom.id }
                        onChange={ ({ selected }) => {
                            setSelectedQuestroom(
                                locationQuestrooms.find((l) => l.id === selected.key),
                            );
                        } }
                        block={ true }
                        optionsListWidth="field"
                    />
                </FlexColumns>

                <Divider />

                <ViewEmail
                    title={ transformText(recordLocale.title, testData) }
                    sections={ sections }
                />
            </FlexColumns>
        </Sidepanel>
    );
};

/* eslint-disable complexity */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    CalendarInput,
    Flex,
    FlexColumns,
    Input,
    Select,
    Tag,
    Textarea,
    Typography,
} from '@alphakits/ui/dist';
import { CreateCertificatesaleDto } from '@escapenavigator/types/dist/certificate-sale/create-certificatesale.dto';
import { CertificatesaleDeliveryTypeEnum } from '@escapenavigator/types/dist/certificate-sale/enum/certificatesales-delivery-type.enum';
import { CertificateRO } from '@escapenavigator/types/dist/certificate/certificate.ro';
import { ClientRO } from '@escapenavigator/types/dist/client/client.ro';
import {
    FormikErrors, FormikHandlers, FormikTouched, FormikValues,
} from 'formik';
import { getOptionForTimeSelect } from 'src/modals/slots/autocomplite/utils';

type Props = {
    values: CreateCertificatesaleDto;
    client: ClientRO;
    setFieldValue: (row, value) => void;
    handleChange: FormikHandlers['handleChange'];
    selectedCertificate: CertificateRO;
    touched: FormikTouched<FormikValues & CreateCertificatesaleDto>;
    errors: FormikErrors<FormikValues & CreateCertificatesaleDto>;
};

export const CertificatesaleDeliveryInfo: React.FC<Props> = ({
    values,
    setFieldValue,
    touched,
    client,
    errors,
    handleChange,
    selectedCertificate,
}) => {
    const deliveryOptions = [];
    const { t, i18n } = useTranslation();
    const certificate = selectedCertificate as unknown as CertificateRO;

    if (certificate) {
        if (certificate.pickupPossibility) {
            deliveryOptions.push({
                key: CertificatesaleDeliveryTypeEnum.PICKUP,
                content: t('options.delivery.pickup'),
            });
        }

        if (certificate.deliveryPossibility) {
            deliveryOptions.push({
                key: CertificatesaleDeliveryTypeEnum.POST,
                content: t('options.delivery.post'),
            });
        }

        if (certificate.emailPossibility) {
            deliveryOptions.push({
                key: CertificatesaleDeliveryTypeEnum.EMAIL,
                content: t('options.delivery.email'),
            });
        }
    }

    return (
        <React.Fragment>
            <Select
                dataTestId="deliveryType"
                label={ t('component.deliveryMethod') }
                options={ deliveryOptions }
                selected={ values.deliveryType }
                onChange={ ({ selected }) => {
                    setFieldValue('deliveryType', selected?.key);
                } }
                error={ touched.deliveryType && errors.deliveryType }
                required={ true }
                block={ true }
            />

            { values.deliveryType && values.deliveryType !== CertificatesaleDeliveryTypeEnum.PICKUP && (
                <Flex gap="xs" justify="start" wrap={ true }>
                    <Tag
                        view={ values.sameRecipient ? 'primary-inverted' : 'primary' }
                        text={ t('Клиент является получателем') }
                        onClick={ () => {
                            setFieldValue('sameRecipient', true);
                            setFieldValue('sendAfterPayment', true);
                            setFieldValue('deliveryEmail', client.email);
                            setFieldValue('deliveryName', client.name);
                            setFieldValue('deliveryPhone', client.phone);
                            setFieldValue('deliverySurname', client.surname);
                            setFieldValue('deliveryAddress', client.address);
                            setFieldValue('deliveryPostcode', client.postcode);
                            setFieldValue('deliveryCity', client.city);
                        } }
                    />
                    <Tag
                        view={ values.sameRecipient ? 'primary' : 'primary-inverted' }
                        text={ t('Другой человек') }
                        onClick={ () => {
                            setFieldValue('sameRecipient', false);
                            setFieldValue('deliveryEmail', undefined);
                            setFieldValue('deliveryName', undefined);
                            setFieldValue('deliveryPhone', undefined);
                            setFieldValue('deliverySurname', undefined);
                            setFieldValue('deliveryAddress', undefined);
                            setFieldValue('deliveryPostcode', undefined);
                            setFieldValue('deliveryCity', undefined);
                        } }
                    />
                </Flex>
            ) }

            { values.deliveryType &&
                !values.sameRecipient &&
                values.deliveryType === CertificatesaleDeliveryTypeEnum.EMAIL && (
                <React.Fragment>
                    <Input
                        dataTestId="deliveryEmail"
                        label={ t('Email адрес получателя сертификата') }
                        value={ values.deliveryEmail }
                        error={ touched.deliveryEmail && errors.deliveryEmail }
                        onChange={ handleChange('deliveryEmail') }
                        block={ true }
                    />

                    <Textarea
                        dataTestId="deliveryMessage"
                        label={ t('Текст поздравления') }
                        value={ values.deliveryMessage }
                        onChange={ handleChange('deliveryMessage') }
                        block={ true }
                        maxLength={ 500 }
                        counter={ true }
                    />

                    <Flex gap="xs" justify="start" wrap={ true }>
                        { /* Отправить сертификат сразу после оплаты */ }
                        <Tag
                            view={ values.sendAfterPayment ? 'primary-inverted' : 'primary' }
                            text={ t('Отправить после оплаты') }
                            onClick={ () => setFieldValue('sendAfterPayment', true) }
                        />
                        <Tag
                            view={ values.sendAfterPayment ? 'primary' : 'primary-inverted' }
                            text={ t('Установить время отправки') }
                            onClick={ () => setFieldValue('sendAfterPayment', false) }
                        />
                    </Flex>

                    { !values.sendAfterPayment && (
                        <React.Fragment>
                            <CalendarInput
                                label={ t('tables.sendedAt') }
                                value={ values.sendingDate }
                                size="m"
                                lang={ i18n.language }
                                error={ touched.sendingDate && (errors.sendingDate as string) }
                                onChange={ (e, { value }) => setFieldValue('sendingDate', value) }
                                block={ true }
                            />

                            <Select
                                dataTestId="sendingTime"
                                label={ t('Время отправки') }
                                options={ getOptionForTimeSelect(30) }
                                selected={ values.sendingTime }
                                onChange={ ({ selected }) =>
                                    setFieldValue('sendingTime', selected?.key) }
                                error={ touched.sendingTime && errors.sendingTime }
                                block={ true }
                            />
                        </React.Fragment>
                    ) }
                </React.Fragment>
            ) }

            { values.deliveryType && values.deliveryType === CertificatesaleDeliveryTypeEnum.POST && (
                <React.Fragment>
                    <FlexColumns columns={ 1 } gr={ 12 }>
                        <Typography.Text view="caps" color="secondary" uppercase={ true }>
                            { t('Получатель сертификата') }
                        </Typography.Text>

                        <Input
                            label={ t('Имя получателя') }
                            block={ true }
                            disabled={ values.sameRecipient && !!client.name }
                            name="name"
                            value={ values.deliveryName }
                            onChange={ handleChange('deliveryName') }
                            error={ touched.deliveryName && errors.deliveryName }
                        />
                    </FlexColumns>

                    <Input
                        label={ t('Фамилия получателя') }
                        value={ values.deliverySurname }
                        disabled={ values.sameRecipient && !!client.surname }
                        block={ true }
                        name="surname"
                        onChange={ handleChange('deliverySurname') }
                        error={ touched.deliverySurname && errors.deliverySurname }
                    />

                    <Input
                        label={ t('Телефон получателя') }
                        value={ values.deliveryPhone }
                        disabled={ values.sameRecipient && !!client.phone }
                        name="phone"
                        block={ true }
                        onChange={ handleChange('deliveryPhone') }
                        error={ touched.deliveryPhone && errors.deliveryPhone }
                    />

                    <FlexColumns columns={ 1 } gr={ 16 }>
                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Typography.Text view="caps" color="secondary" uppercase={ true }>
                                { t('address') }
                            </Typography.Text>

                            <Input
                                label={ t('address') }
                                value={ values.deliveryAddress }
                                disabled={ values.sameRecipient && !!client.address }
                                name="address"
                                onChange={ handleChange('deliveryAddress') }
                                error={ touched.deliveryAddress && errors.deliveryAddress }
                                block={ true }
                            />
                        </FlexColumns>

                        <FlexColumns columns={ 2 } gr={ 16 }>
                            <Input
                                label={ t('Postcode') }
                                value={ values.deliveryPostcode }
                                disabled={ values.sameRecipient && !!client.postcode }
                                name="postcode"
                                block={ true }
                                autoComplete="postal-code"
                                onChange={ handleChange('deliveryPostcode') }
                                error={ touched.deliveryPostcode && errors.deliveryPostcode }
                            />

                            <Input
                                label={ t('Город') }
                                value={ values.deliveryCity }
                                disabled={ values.sameRecipient && !!client.city }
                                name="city"
                                block={ true }
                                autoComplete="address-level2"
                                onChange={ handleChange('deliveryCity') }
                                error={ touched.deliveryCity && errors.deliveryCity }
                            />
                        </FlexColumns>
                    </FlexColumns>
                </React.Fragment>
            ) }
        </React.Fragment>
    );
};

import React from 'react';
import { CertificateSaleRO } from '@escapenavigator/types/dist/certificate-sale/certificate-sale.ro';
import { OrderRO } from '@escapenavigator/types/dist/order/order.ro';
import { TransactionTypeEnum } from '@escapenavigator/types/dist/transaction/enum/transaction-type.enum';

import { Transaction } from './transaction';

type Props = {
    transactions: OrderRO['transactions'] | CertificateSaleRO['transactions'];
};

export const TransactionsList: React.FC<Props> = ({ transactions = [] }) => {
    if (!transactions.length) return null;

    return (
        <React.Fragment>
            { transactions
                .filter(
                    (tr) =>
                        tr.type === TransactionTypeEnum.INSIDE ||
                        tr.type === TransactionTypeEnum.PROCESSING ||
                        tr.type === TransactionTypeEnum.SUCCEEDED,
                )
                .map((transaction) => (
                    <Transaction key={ +transaction.createdAt } transaction={ transaction } />
                )) }
        </React.Fragment>
    );
};

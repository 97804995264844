/* eslint-disable complexity */
import React from 'react';
import { FiltersMapper } from '@alphakits/ui/dist/table/types';
import { TFunction } from 'i18next';

import { Cashbox } from './cashbox';
import { FromToDate } from './from-to-date';
import { FromToValue } from './from-to-value';
import { Questroom } from './questroom';
import { SimpleRowValue } from './simple-row-value';
import { User } from './user';

export const mapper =
    (t: TFunction) =>
        ({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
            paramName,
            paramValue,
            onDelete,
        }: FiltersMapper) => {
            switch (paramName) {
                case 'lastGame':
                case 'created':
                case 'date':
                    return (
                        (paramValue[0] || paramValue[1]) && (
                            <FromToDate
                                removeParam={ onDelete }
                                row={ paramName }
                                value={ paramValue }
                                rowField={ t(`filters.${paramName}`) }
                                t={ t }
                            />
                        )
                    );

                case 'gamesCount':
                    return (
                        (paramValue[0] || paramValue[1]) && (
                            <FromToValue
                                removeParam={ onDelete }
                                row={ paramName }
                                value={ paramValue }
                                rowField={ t(`filters.${paramName}`) }
                                t={ t }
                            />
                        )
                    );

                    // case 'userId':
                    //     return (
                    //         <User
                    //             removeParam={ onDelete }
                    //             row={ paramName }
                    //             value={ paramValue }
                    //             t={ t }
                    //         />
                    //     );

                case 'moderatorsIds':
                    return (
                        <User
                            removeParam={ onDelete }
                            row={ t('staffConductedGame') }
                            value={ paramValue }
                            t={ t }
                        />
                    );

                case 'userId':
                    return <User removeParam={ onDelete } row="" value={ paramValue } t={ t } />;

                case 'cashboxId':
                    return <Cashbox removeParam={ onDelete } row={ paramName } value={ paramValue } t={ t } />;

                case 'visitedQuestroomsIds':
                    return (
                        <Questroom
                            removeParam={ onDelete }
                            row={ paramName }
                            value={ paramValue }
                            rowField={ t('component.wasOnQuest') }
                            t={ t }
                        />
                    );

                case 'notVisitedQuestroomsIds':
                    return (
                        <Questroom
                            removeParam={ onDelete }
                            row={ paramName }
                            value={ paramValue }
                            rowField={ t('component.wasntOnQuest') }
                            t={ t }
                        />
                    );

                case 'questroomsIds':
                    return (
                        <Questroom removeParam={ onDelete } row={ paramName } value={ paramValue } t={ t } />
                    );

                case 'status':
                    return <SimpleRowValue removeParam={ onDelete } row={ paramName } text={ paramValue } />;

                case 'payed':
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            text={ paramValue ? t('paidUp') : t('notPaidUp') }
                            row={ paramName }
                        />
                    );

                case 'players':
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            row={ paramName }
                            text={ `${paramValue} ${t('ppl')}` }
                        />
                    );

                case 'language':
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            row={ paramName }
                            text={ `${paramValue}`.toLocaleUpperCase() }
                        />
                    );

                case 'financeitemId':
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            row={ paramName }
                            text={ `${t('financeitemId')} ${paramValue}` }
                        />
                    );

                case 'source':
                case 'delivery':
                case 'transactionType':
                case 'orderStatus':
                case 'certificateStatus':
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            row={ paramName }
                            text={ t(`options.${paramName}.${paramValue}`) }
                        />
                    );

                default:
                    return (
                        <SimpleRowValue
                            removeParam={ onDelete }
                            row={ paramName }
                            text={ t(`filters.${paramName}.${paramValue}`) }
                        />
                    );
            }
        };
